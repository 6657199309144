<script setup>
import { JTab } from 'media-flow-ui'
import { toRefs, ref, computed } from 'vue'

import TabData from '~~/models/layout/tabs/TabData'
import { usePageBuilder } from '~/stores/page-builder'
import { JUSTIFY_CONTENT_OPTIONS } from '~~/constants/style'

import { formatIconStyle } from '~/helpers/icon/icon'
import { formatComponentStyle } from '@/models/layout/style/component'

const pageBuilderStore = usePageBuilder()

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
  rowOrder: {
    type: Number,
    required: false,
    default: null
  }
})

const route = useRoute()
const router = useRouter()

const tabsData = ref([])
const tabActiveIndex = ref(0)

const { data } = toRefs(props)

const isChangeOnHover = data.value.changeOnHover
const allowToSaveTabOnUrl = data.value.props?.allowToSaveTabOnUrl || false

const style = computed(() =>
  formatComponentStyle({
    alignment: data.value?.style.alignment,
    gap: data.value?.style.gap,
    width: data.value?.style.width,
  })
)
const tabItemStyle = computed(() =>
  formatComponentStyle({
    ...data.value?.style,
    borderProperties: {
      ...data.value?.style?.borderProperties,
      show: true,
    },
    width: null,
    gap: null,
    borderStyle: 'solid',
    textAlign: data.value?.style?.alignment || 'left',
  })
)

const iconsStyle = computed(() => formatIconStyle(data.value?.icon))

const wrapperClasses = computed(
  () =>
    `${JUSTIFY_CONTENT_OPTIONS[data.value?.style?.alignment] || 'justify-left'}`
)
const promises = ref(-1)
const promisesData = ref([])

await useAsyncData(async () => {
  promises.value = data.value.tabs.map(async (tab, tabsIndex) => {
    if (tab.isDynamic) {
      const queryId = tab.settings?.query.id
      const params = {
        take: tab.settings?.take || tab.settings?.limit,
        // orderby: tab.settings?.order,
      }

      let response
      try {
        const res = await pageBuilderStore.actionGetQueryDataInitClient(queryId, params, null, null, props.rowOrder)

        if (res) {
          response = res.response
        }
      } catch (error) {
        console.error(error)
      }



      if (!response) return

      const tabs = response.data.map((rawTab, tabIndex) => {
        const tabData = new TabData()

        //set layout data foreach tab static ore tab dynamic(tabs)
        tabData.setTabLayout(tab.layout[0])

        tabData.setTabName(rawTab.title)
        tabData.setTabContentId(rawTab.contentId)

        tabData.setTabManagement(tabsIndex, tabIndex)

        return tabData
      })

      tabsData.value.push(...tabs) //or tabs
    } else {
      // handle static tab isDynamic = false

      const tabData = new TabData()
      tabData.setTabName(tab.data.name)
      tabData.setTabLinkData(tab.data.linkData, data.value?.changeOnHover)
      tabData.setTabLayout(tab.layout[0])

      tabsData.value.push(tabData) //or tabs
    }
  })
  await Promise.all(promises.value)
    .then(([results]) => {
      if (!results) {
        throw 'Issue setting tabs data'
      }

      promisesData.value = results.data

      const tabs = results.data.map((rawTab, tabIndex) => {
        const tabData = new TabData()

        //set layout data foreach tab static ore tab dynamic(tabs)
        tabData.setTabLayout(data.value.tabs[0].layout[0])

        tabData.setTabName(rawTab.title)
        tabData.setTabContentId(rawTab.contentId)

        tabData.setTabManagement(0, tabIndex)

        return tabData
      })

      tabsData.value.push(...tabs) //or tabs
    })
    .catch((error) => {
      console.error(error)
    })

    // return tabsData.value
})

const tabNameByQueryUrl = route.query.tab

let indexFound = 0

if (tabNameByQueryUrl) {
  indexFound = tabsData.value
    .map((t) => t.name)
    .findIndex((i) => i === tabNameByQueryUrl)

  indexFound = indexFound === -1 ? 0 : indexFound

  if (indexFound != -1) {
    tabActiveIndex.value = indexFound
  }
}

tabsData.value.map((t, i) => {
  if (i === indexFound) {
    t.loaded = true
  } else {
    t.loaded = false
  }

  return t
})

const tabClick = ({ tabName: tab, tabIndex }) => {
  tabsData.value[tabIndex].loaded = true
  tabActiveIndex.value = tabIndex

  if (isChangeOnHover || !allowToSaveTabOnUrl) return

  router.replace({
    query: {
      tab,
    },
  })
}

onMounted(() => { })
// });

//grid grid-cols-1
// grid-cols-1
// grid-cols-2
// grid-cols-3
// grid-cols-4
// grid-cols-5
// grid-cols-6
// grid-cols-7
// grid-cols-8
// grid-cols-9
// grid-cols-10

// md:grid-cols-1
// md:grid-cols-2
// md:grid-cols-3
// md:grid-cols-4
// md:grid-cols-5
// md:grid-cols-6
// md:grid-cols-7
// md:grid-cols-8
// md:grid-cols-9
// md:grid-cols-10
</script>

<template>
  <div>
    <!-- <pre style="color: red">
      {{tabItemStyle}}
    </pre> -->
    <JTab :tabs="tabsData" :onTabClickCallback="tabClick" :activeTab="tabActiveIndex" :change-on-hover="isChangeOnHover"
      :wrapper-classes="wrapperClasses" :tab-item-style="tabItemStyle" :layout-type="data.props.layoutType"
      :is-vertical="data.props.isVertical" :is-carousel="data.props.isCarousel" :wrapper-style="style"
      :icons-style="iconsStyle">
      <template #tab="data">
        <Widget v-if="data.tab.loaded" :data="data.tab" :rowOrder="rowOrder" />
      </template>
    </JTab>
  </div>
</template>

<style></style>
